module.exports = {
    secToTime: function(sec, showSign) {
        sec = Math.round(sec);
        let sign = '';
        if (sec < 0) {
            sec *= -1;
            sign = '-';
        } else if (showSign) {
            sign = '+';
        }
        let hrs = Math.floor(sec / 3600);
        sec -= hrs * 3600;
        let mnts = Math.floor(sec / 60);
        sec -= mnts * 60;
        return sign + hrs + ":" + this.pad(mnts, 2) + ":" + this.pad(sec, 2);
    },

    pad: function(num, size) {
        const s = "000000000" + num;
        return s.substr(s.length - size);
    },

    formatDate: function(date) {
        return this.pad(date.getFullYear(), 4) + '-' + this.pad(date.getMonth() + 1, 2) + '-' + this.pad(date.getDate(), 2);
    },

    formatTime: function(date, seconds) {
        if (!this.isValidDate(date)) {
            return '';
        }
        if (seconds) {
            return this.pad(date.getHours(), 2) + ':' + this.pad(date.getMinutes(), 2) + ':' + this.pad(date.getSeconds(), 2);
        }
        return this.pad(date.getHours(), 2) + ':' + this.pad(date.getMinutes(), 2);
    },

    dateAdd: function(fromDate, pdelta) {
        let date = new Date(fromDate.getTime());
        let unit = pdelta.substr(pdelta.length - 1);
        let delta = parseInt(pdelta.substr(0, pdelta.length - 1));
        if (unit === 'd') {
            date = new Date(date.setDate(date.getDate() + delta));
        } else if (unit === 'h') {
            date = new Date(date.setHours(date.getHours() + delta));
        } else if (unit === 'm') {
            date = new Date(date.setMinutes(date.getMinutes() + delta));
        } else if (unit === 's') {
            date = new Date(date.setSeconds(date.getSeconds() + delta));
        } else {
            date = new Date(date.setSeconds(date.getSeconds() + parseInt(pdelta)));
        }
        return date;
    },

    isValidDate: function(d) {
        return d instanceof Date && !isNaN(d);
    }
};
const $ = require('jquery');

module.exports = {

    callbacks: {},

    settings: {},

    loadSettings: function() {
        const settings = JSON.parse(localStorage.getItem('dummyUpstream'));
        this.settings = settings === null ? {
            timeOffset: 0,
            schedule: []
        } : settings;
    },

    storeSettings: function() {
        localStorage.setItem('dummyUpstream', JSON.stringify(this.settings));
    },

    connect: function(server) {
        this.loadSettings();
        if (this.callbacks['connect']) {
            this.callbacks['connect']();
        }
    },

    message: function(event, data) {
        if (this.callbacks[event]) {
            this.callbacks[event](data);
        }
    },

    attachEvent: function(event, callback) {
        this.callbacks[event] = callback;
    },

    emit: function(name, params) {
        if (name === 'init') {
            const client = {
                id: 'single-user',
                name: params.name === null ? 'You' : params.name,
                showClock: params.showClock === null ? false : params.showClock,
                backstageMode: params.backstageMode === null ? false : params.backstageMode,
                disableAdmin: false,
                disconnected: null
            };
            this.message('client-settings', client);
            this.message('clients', [client]);
            let now = new Date();
            this.message('time-sync', now.setMilliseconds(now.getMilliseconds() + this.settings.timeOffset));
            this.message('schedule', this.settings.schedule);
        }
        if (name === 'client-admin-update') {
            this.message('client-settings', params);
        }
        if (name === 'config-update') {
            params.schedule = this.settings.schedule;
            this.message('config', params);
        }
        if (name === 'time-sync') {
            this.settings.timeOffset = new Date(params) - (new Date());
            this.storeSettings();
            this.message('time-sync', params);
        }
        if (name === 'schedule-update') {
            this.settings.schedule = params;
            this.storeSettings();
            this.message('schedule', params);
        }
    },

    attachClientSettings: function(clientSettings) {
        $('.app-settings .backstage').on('change', function(e) {
            settings = clientSettings.serialize();
            settings['backstageMode'] = $(this).is(':checked');
            clientSettings.update(settings);
        });
    }
};
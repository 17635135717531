const io = require('socket.io-client');

module.exports = {

    socket: null,
    reconnectInterval: null,
    callbacks: {},
    url: null,

    connect: function(server) {
        this.url = "ws://" + (server === null ? location.host : server) + "/showtimer";
        this.reconnect();
    },

    reconnect: function() {
        if (this.socket !== null) {
            this.socket.close();
        }

        const self = this;
        this.socket = new WebSocket(this.url);
        this.socket.onopen = function() {
            clearInterval(self.reconnectInterval);
            if (self.callbacks['connect']) {
                self.callbacks['connect']();
            }

            self.socket.onmessage = function(event) {
                const message = JSON.parse(event.data);
                if (self.callbacks[message['event']]) {
                    self.callbacks[message['event']](message['data']);
                }
            }
            self.socket.onerror = function() {
                self.socket.close();
                self.socket = null;
            }
            self.socket.onclose = function() {
                self.socket = null;
                self.reconnectInterval = setInterval(function () {
                    self.reconnect();
                }, 5000);
                if (self.callbacks['disconnect']) {
                    self.callbacks['disconnect']();
                }
            };
        }
    },

    attachEvent: function(event, callback) {
        this.callbacks[event] = callback;
    },

    emit: function(name, params) {
        if (this.socket !== null) {
            this.socket.send(JSON.stringify({
                event: name,
                data: params
            }));
        }
    }
};
const $ = require('jquery');
const dateHelper = require('./dateHelper.js');

let clientOffset = 0;

function getCurrentTime() {
    let now = new Date();
    now.setMilliseconds(now.getMilliseconds() + clientOffset);
    return now;
}

function draw($container) {
    const now = getCurrentTime();
    $container.find('.timeoffset-year-input').text(now.getFullYear());
    $container.find('.timeoffset-month-input').text(dateHelper.pad(now.getMonth()+1, 2));
    $container.find('.timeoffset-day-input').text(dateHelper.pad(now.getDate(), 2));
    $container.find('.timeoffset-hour-input').text(dateHelper.pad(now.getHours(), 2));
    $container.find('.timeoffset-minute-input').text(dateHelper.pad(now.getMinutes(), 2));
    $container.find('.timeoffset-second-input').text(dateHelper.pad(now.getSeconds(), 2));
};

$.fn.timeoffset = function(options, param1) {
    if (typeof options === 'string') {
        if (options === 'clientOffset') {
            clientOffset = parseInt(param1);
            return;
        }
    }

    let settings = $.extend({
    }, options );

    return this.each(function() {
        const $container = $('<div class="timeoffset-container" />');
        $(this).append($container);
        $.each(['year', 'month', 'day', 'hour', 'minute', 'second'], function(i, part) {
            if (i === 3) {
                $container.append('<div class="timeoffset-spacer"> </div>');
            } else if (i > 3) {
                $container.append('<div class="timeoffset-spacer">:</div>');
            } else if (i > 0) {
                $container.append('<div class="timeoffset-spacer">-</div>');
            }
            $container.append(
                '<div class="timeoffset-part-container timeoffset-part-' + part + '">' +
                '   <div class="timeoffset-part-up" data-change="' + part + '"><i></i></div>' +
                '   <div class="timeoffset-' + part + '-input timeoffset-part-input"></div>' +
                '   <div class="timeoffset-part-down" data-change="-' + part + '" ><i></i></div>' +
                '</div>'
            );
        });

        $container.on('click', function(e) {
            let $target = $(e.target);
            if ($target.is('i')) {
                $target = $target.parent();
            }
            if ($target.attr('data-change')) {
                const sign = $target.attr('data-change').substr(0, 1) === '-' ? -1 : 1;
                let change = sign === 1 ? $target.attr('data-change') : $target.attr('data-change').substr(1);

                let masterNow = getCurrentTime();
                if (change === 'year') {
                    masterNow.setFullYear(masterNow.getFullYear() + sign);
                } else if (change === 'month') {
                    masterNow.setMonth(masterNow.getMonth() + sign);
                } else if (change === 'day') {
                    masterNow.setDate(masterNow.getDate() + sign);
                } else if (change === 'hour') {
                    masterNow.setHours(masterNow.getHours() + sign);
                } else if (change === 'minute') {
                    masterNow.setMinutes(masterNow.getMinutes() + sign);
                } else if (change === 'second') {
                    masterNow.setSeconds(masterNow.getSeconds() + sign);
                }

                $container.trigger('timeoffset:change', [masterNow]);
                draw($container);
            }
        });

        $('body').on('heartbeat:seconds', function() {
            draw($container);
        });
        draw($container);
    });
};

const $ = require('jquery');

let client = {
    id: null,
    name: null,
    showClock: null,
    backstageMode: null,
    disableAdmin: null
};

module.exports = {
    serialize: function() {
        return client;
    },
    isValid: function(settings) {
        return settings.name && settings.name.match(/^[a-zA-Z0-9\-]{3,30}$/);
    },
    update: function(settings) {
        if (!this.isValid(settings)) {
            return;
        }
        for (const [key, value] of Object.entries(client)) {
            if (key in settings) {
                client[key] = settings[key];
            }
        }
        $('body').attr('data-backend-clientid', client.id);
        localStorage.setItem('client-settings', JSON.stringify(client));
        $('body').trigger('clientsettings:update', [settings]);
    },
    init: function() {
        const local = JSON.parse(localStorage.getItem('client-settings'));
        this.update(local === null ? {} : local);
    }
};

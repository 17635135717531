const $ = require('jquery');

module.exports = {
    timeAgo: null
};

function isValid(client) {
    return client.name && client.name.match(/^[a-zA-Z0-9\-]{3,30}$/);
};

function setValues($client, client) {
    setReadOnlyStatus($client, false);
    $client.attr('data-id', client.id);
    $client.find('input.name').val(client.name);
    if (client.ip && client.ip.match('.')) {
        $client.find('.ip').text(client.ip.replace('::ffff:', ''));
    }
    $client.find('input.clock').prop('checked', client.showClock);
    $client.find('input.backstage').prop('checked', client.backstageMode);
    $client.find('input.disableadmin').prop('checked', client.disableAdmin);
    $client.find('.itsme').hide();
    $client.find('.identify').show();
    if (client.id === $('body').attr('data-backend-clientid')) {
        $client.find('.itsme').show();
        $client.find('.identify').hide();
        $client.find('input.disableadmin').prop('readonly', true).prop('disabled', true);
    }
    $client.find('.offline').hide();
    if (client.disconnected) {
        $client.find('.identify').hide();
        $client.find('.offline').show();
        $client.find('.offline-since').text(module.exports.timeAgo.format(new Date(client.disconnected)));
        $client.find('.offline-since').attr('data-timestamp', (new Date(client.disconnected)).getTime());
        setReadOnlyStatus($client, true);
    } else {
        $client.find('.offline-since').attr('data-timestamp', null);
    }
};

function draw($container, clients) {
    if (clients.length === 0) {
        $container.find('.clients-connected').hide();
        $container.find('.no-clients').show();
        return;
    }

    $container.find('.clients-connected').show();
    $container.find('.no-clients').hide();

    const $list = $container.find('.clients');
    let template;
    if ($('body').hasClass('single-user-mode')) {
        template = $list.attr('data-template-singleuser');
    } else {
        template = $list.attr('data-template');
    }
    $list.find('.client').remove();
    $.each(clients, function(idx, client) {
        if (isValid(client)) {
            const $client = $(template);
            setValues($client, client);
            if (client.id === $('body').attr('data-backend-clientid')) {
                $list.prepend($client);
            } else {
                $list.append($client);
            }
        }
    });
};

function setReadOnlyStatus($client, readonly) {
    const fields = ['input.clock', 'input.backstage', 'input.disableadmin', 'input.name'];
    $.each(fields, function(k, selector) {
        $client.find(selector).prop('readonly', readonly).prop('disabled', readonly);
    });
};

function serialize($client) {
    return {
        id: $client.attr('data-id'),
        name: $client.find('input.name').val(),
        showClock: $client.find('input.clock').prop('checked'),
        backstageMode: $client.find('input.backstage').prop('checked'),
        disableAdmin: $client.find('input.disableadmin').prop('checked')
    };
};

function watchDisconnectedTimestamps($container) {
    setInterval(function() {
        $container.find('.offline-since').each(function() {
            if (typeof $(this).attr('data-timestamp') === 'string') {
                $(this).text(module.exports.timeAgo.format(new Date(parseInt($(this).attr('data-timestamp')))));
            }
        });
    }, 60 * 1000);
};

$.fn.clientsAdmin = function(options, param1) {
    if (typeof options === 'string') {
        return this.each(function() {
            let $container = $(this);
            if (options === 'clients') {
                draw($container, param1);
            }
        });
    }

    let settings = $.extend({
    }, options );

    return this.each(function() {
        const $container = $(this);
        draw($container, []);

        $container.on('click', '.identify', function(e) {
            const $target = $(e.target);
            const $client = $target.closest('.client');
            const client = serialize($client);
            $container.trigger('client:identify', [client]);
        });

        $container.on('change', function(e) {
            const $target = $(e.target);
            const $client = $target.closest('.client');
            const client = serialize($client);
            if (isValid(client)) {
                $container.trigger('client:change', [client]);
            }
        });

        watchDisconnectedTimestamps($container);
    });
};

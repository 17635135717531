import './style.css';

const $ = require('jquery');

import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
TimeAgo.addDefaultLocale(en);
const timeAgo = new TimeAgo('en-US');

require('inputmask/dist/jquery.inputmask.js');
require('./offline.js');
require('./settings.js');
require('./scheduleAdmin.js');
require('./appSettings.js');
require('./timer.js');
require('./clientsAdmin.js').timeAgo = timeAgo;

const clientSettings = require('./clientSettings.js');
clientSettings.init();

$('.showtimer-version').text(SHOWTIMER_VERSION);

let heartBeatSeconds = setInterval(function() {
    $('body').trigger('heartbeat:seconds');
}, 1000);

$('body').removeClass('loading');
$('.noscript').remove();

$('.backend-time').timeoffset();
$('.timer-container').timer();
$('.app-settings').appSettings();
$('.admin-clients').clientsAdmin();
$('.admin-schedule').scheduleAdmin();

$('#force-time-sync').click(function() {
    backend.emit('force-time-sync');
    return false;
});
$('#use-local-time').click(function() {
    backend.emit('time-sync', new Date());
    return false;
});

let backend;
const singleUser = (new URLSearchParams(window.location.search)).get('su');
if (singleUser || window.location.hostname === 'app.showtimer.nl') {
    backend = require('./singleUser.js');
    backend.attachClientSettings(clientSettings);
    $('body').addClass('single-user-mode');
} else {
    backend = require('./wsUpstream.js');
}
//require('./control.js').attachBackend(backend);

backend.attachEvent('connect', function() {
    $('#disconnected').hide();
    backend.emit('init', clientSettings.serialize());
});
backend.attachEvent('clients', function(clients) {
    $('.admin-clients').clientsAdmin('clients', clients);
});
backend.attachEvent('client-settings', function(settings) {
    clientSettings.update(settings);
});
backend.attachEvent('client-identify', function() {
    $('.timer-container').timer('identify');
});
backend.attachEvent('schedule', function(schedule) {
    $('.admin-schedule').scheduleAdmin('schedule', schedule);
    $('.timer-container').timer('schedule', schedule);
});
backend.attachEvent('disconnect', function() {
    $('#disconnected').show();
});
backend.attachEvent('ipaddresses', function(addresses) {
    $('.ipaddresses-container').removeClass('hidden');
    $('.ipaddresses').html('');
    $.each(addresses, function(k, addr) {
        const $li = $('<li></li>');
        $li.text(document.location.protocol + '//' + addr + ':' + document.location.port);
        $('.ipaddresses').append($li);
    });
});
backend.attachEvent('config', function(config) {
    $('.admin-schedule').scheduleAdmin('schedule', config.schedule);
    $('.timer-container').timer('schedule', config.schedule);
    $('.app-settings').appSettings('update', config);
});
backend.attachEvent('time-sync', function(backendDate) {
    const timeOffset = new Date(backendDate) - (new Date());
    $('.timer-container').timer('timeOffset', timeOffset);
    $('.backend-time').timeoffset('clientOffset', timeOffset);
    clearInterval(heartBeatSeconds);
    $('body').trigger('heartbeat:seconds');
    setTimeout(function() {
        heartBeatSeconds = setInterval(function() {
            $('body').trigger('heartbeat:seconds');
        }, 1000);
    }, 1000 - (new Date(backendDate)).getMilliseconds());
});
backend.connect(null);

$('body').on('goto', function(e, $anchor) {
    window.scrollTo(0, $anchor.offset().top);
});
$('body').on('clientsettings:update', function(e, client) {
    $('.timer-container').timer('showClock', client.showClock);
    $('.timer-container').timer('backstageMode', client.backstageMode);
    if (client.disableAdmin) {
        $('body').removeClass('admin');
        $('body').addClass('admin-disabled');
    } else {
        $('body').removeClass('admin-disabled');
    }
});
$('.backend-time').on('timeoffset:change', function(e, newDate) {
    backend.emit('time-sync', newDate);
});
$('.app-settings').on('appsettings:change', function(e, settings) {
    backend.emit('config-update', settings);
    $('.app-settings').trigger('appsettings:update', [settings]);
});
$('.app-settings').on('appsettings:update', function(e, settings) {
    $('.timer-container').timer('warnTime', settings.warnTime);
    $('.timer-container').timer('scheduleOffset', settings.scheduleOffset);
});
$('.app-settings').on('schedule:import', function(e, schedule) {
    $('.admin-schedule').scheduleAdmin('import', schedule);
});
$('.app-settings').on('schedule:export', function(e) {
    $('.admin-schedule').scheduleAdmin('export');
});
$('.admin-clients').on('client:change', function(e, client) {
    backend.emit('client-admin-update', client);
});
$('.admin-clients').on('client:identify', function(e, client) {
    backend.emit('client-identify', client);
});
$('.admin-schedule').on('schedule:change', function(e, schedule) {
    backend.emit('schedule-update', schedule);
    $('.timer-container').timer('schedule', schedule);
});

let pageHeight = $(document).height();
$(window).on('resize', function() {
    const currentOffset = window.scrollY;
    const newPageHeight = $(document).height();
    const dHeight = newPageHeight / pageHeight;
    window.scrollTo(0, currentOffset * dHeight);
    pageHeight = newPageHeight;
});

let menuScrollTimeout = null;
$(window).on('scroll', function() {
    clearTimeout(menuScrollTimeout);
    menuScrollTimeout = setTimeout(function() {
        let $last;
        $('.menu a.menuitem').each(function() {
            const id = $(this).attr('href');
            if (id === '#') {
                return true;
            }
            const $anchor = $(id);
            if ($anchor.offset().top > window.scrollY + $(window).height()) {
                return false;
            }
            $last = $(this);
        });
        $('.menu .current').removeClass('current');
        $last.addClass('current');
    }, 200);
});
$('.menu a.menuitem').on('click', function() {
    $('.menu .current').removeClass('current');
    $(this).addClass('current');
});
$('.menu .toggle').click(function() {
    $('body').toggleClass('admin');
    $(window).scroll();
});
if (document.location.hash) {
    $('.menu .toggle').trigger('click');
}
document.onkeydown = function(e) {
    if (e.ctrlKey && e.key === 'u') {
        let settings = clientSettings.serialize();
        settings.disableAdmin = false;
        $('.admin-clients').trigger('client:change', [settings]);
        return false;
    }
};

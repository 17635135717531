const $ = require('jquery');
const dateHelper = require('./dateHelper.js');

let timeOffset = 0;

function getCurrentTime(offset) {
    let now = new Date();
    if (!offset) {
        offset = 0;
    }
    now.setMilliseconds(now.getMilliseconds() + timeOffset);
    now.setSeconds(now.getSeconds() + offset);
    return now;
}

function drawClock($container, $timerMain, $timerSub) {
    $timerSub.data('contents', 'clock');
    $container.removeClass('warn');
    $timerSub.hide();
    $timerMain.text(dateHelper.formatTime(getCurrentTime(), true));
};

function drawSchedule($container, $timerMain, $timerSub) {
    const settings = $container.data('settings');
    const now = getCurrentTime(settings.scheduleOffset);

    $container.removeClass('warn').addClass('backstage');
    $timerSub.show().text('').find('.backstage-schedule').remove();

    const template = $container.attr('data-schedule-template');
    const $scheduleContainer = $('<div class="backstage-schedule"></div>');
    const $currentContainer = $('<div class="current"></div>');
    $scheduleContainer.append($currentContainer);

    let itemsFound = 0;
    let pastCurrent = false;
    let previousEntryEnd = now;
    const maxItems = 3;
    $.each(settings.schedule, function(idx, entry) {
        let inChangeOver = false;
        if (entry.end < now) {
            return true;
        }
        if (entry.start < now) {
            $currentContainer.html($container.attr('data-schedule-now-playing-template'));
            $currentContainer.append(entry.description);
            pastCurrent = true;
            previousEntryEnd = entry.end;
            return true;
        }
        if (entry.start - now > settings.lookAheadHours * 3600 * 1000) {
            return false;
        }
        if (!pastCurrent && entry.start > now) {
            $currentContainer.html($container.attr('data-schedule-changeover-template'));
            pastCurrent = true;
            inChangeOver = true;
        }

        // Only add changeover entry if more than 1 minute
        if (!inChangeOver && entry.start - previousEntryEnd > 60 * 1000) {
            itemsFound++;
            const $entry = $(template);
            $entry.addClass('changeover');
            $entry.find('.start').text(dateHelper.formatTime(previousEntryEnd));
            $entry.find('.description').text('Changeover');
            $scheduleContainer.append($entry);
        }
        if (itemsFound >= maxItems) {
            return false;
        }

        itemsFound++;
        const $entry = $(template);
        $entry.find('.start').text(dateHelper.formatTime(entry.start));
        $entry.find('.description').text(entry.description);
        $scheduleContainer.append($entry);

        previousEntryEnd = entry.end;

        if (itemsFound >= maxItems) {
            return false;
        }
    });
    $timerSub.append($scheduleContainer);
    $timerSub.data('contents', 'schedule');
};

function draw($container, $timerMain, $timerSub) {
    const settings = $container.data('settings');
    const now = getCurrentTime(settings.scheduleOffset);

    $('#noschedule').hide();

    $container.removeClass('backstage');
    $('.schedule-restart-current, .schedule-skip-to-next').hide();

    if (settings.showClock) {
        return drawClock($container, $timerMain, $timerSub);
    }

    let previousEntryEnd = now;
    let itemFound = false;
    $.each(settings.schedule, function(idx, entry) {
        if (entry.end < now) {
            previousEntryEnd = entry.end;
            return true;
        }
        if (entry.start < now) {
            const diff = (entry.end - now) / 1000;
            itemFound = true;
            $timerSub.hide();
            $timerMain.text(dateHelper.secToTime(diff));
            if (diff < settings.warnTime * 60) {
                $container.addClass('warn');
            } else {
                $container.removeClass('warn');
            }
            $('.schedule-restart-current').show().attr('data-schedule-offset', (entry.start - now) / 1000);
            $('.schedule-skip-to-next').show().attr('data-schedule-offset', (entry.end - now) / 1000);
            previousEntryEnd = entry.end;
            return false;
        }
        if (entry.start - now > settings.lookAheadHours * 3600 * 1000) {
            return false;
        }
        if (entry.start > now) {
            itemFound = true;
            if ($timerSub.data('contents') !== 'changeover') {
                const $changeover = $($container.attr('data-changeover-template'));
                $timerSub.html($changeover);
                $timerSub.data('contents', 'changeover');
            }
            $timerSub.show();
            $container.removeClass('warn');
            const timeChangeOver = dateHelper.secToTime((entry.start - now) / 1000);
            const timeNext = dateHelper.secToTime(entry.duration * 60);
            $timerMain.text(timeNext);
            $timerSub.find('.changeover-timer').text(timeChangeOver);
            if ($('body').hasClass('admin') || settings.backstageMode) {
                $timerMain.text(timeChangeOver);
            }
            if (previousEntryEnd - now < 0) {
                $('.schedule-restart-current').show().attr('data-schedule-offset', (previousEntryEnd - now) / 1000);
            }
            $('.schedule-skip-to-next').show().attr('data-schedule-offset', (entry.start - now) / 1000);
            previousEntryEnd = entry.end;
            return false;
        }
    });

    if ($('body').hasClass('admin')) {
        $timerSub.hide();
        $container.removeClass('warn');
    } else if (settings.backstageMode) {
        drawSchedule($container, $timerMain, $timerSub);
    }

    if (!itemFound) {
        $('#noschedule').show();
        return drawClock($container, $timerMain, $timerSub);
    }
};

function store(timer) {
    let settings = $.extend(true, {}, timer);
    settings.schedule = null;
    localStorage.setItem('timer', JSON.stringify(settings));
};

function isValid(timer) {
    return true;
}

function parseSchedule(settings) {
    const now = getCurrentTime(settings.scheduleOffset);

    if (!settings.scheduleRaw) {
        return;
    }
    settings.schedule = [];

    if (settings.scheduleOffset) {
        $('#offset-warning').show().find('.offset-human').text(dateHelper.secToTime(settings.scheduleOffset, true));
    } else {
        $('#offset-warning').hide();
    }

    $.each(settings.scheduleRaw, function(idx, scheduleDate) {
        $.each(scheduleDate.entries, function(idx, entry) {
            const start = new Date(entry.start);
            const end = dateHelper.dateAdd(start, entry.duration + 'm');
            if (end < now - 7200 * 1000) {
                // item has ended too long ago
                return true;
            }
            settings.schedule.push({
                start: start,
                end: end,
                duration: entry.duration,
                description: entry.description ? entry.description : 'Untitled'
            });
        });
    });

    settings.schedule.sort(function (a, b) {
        if (a.start < b.start) {
            return -1;
        }
        if (a.start > b.start) {
            return 1;
        }
        return 0;
    });
}

$.fn.timer = function(options, param1) {
    if (typeof options === 'string') {
        return this.each(function() {
            const $container = $(this);
            if (options === 'showClock') {
                let settings = $container.data('settings');
                settings.showClock = param1;
                $container.data('settings', settings);
                store(settings);
            } else if (options === 'backstageMode') {
                let settings = $container.data('settings');
                settings.backstageMode = param1;
                $container.data('settings', settings);
                store(settings);
            } else if (options === 'schedule') {
                let settings = $container.data('settings');
                settings.scheduleRaw = $.extend(true, {}, param1);
                parseSchedule(settings);
                $container.data('settings', settings);
                store(settings);
            }  else if (options === 'warnTime') {
                let settings = $container.data('settings');
                settings.warnTime = parseInt(param1);
                $container.data('settings', settings);
                store(settings);
            } else if (options === 'timeOffset') {
                let settings = $container.data('settings');
                timeOffset = parseInt(param1);
                settings.timeOffset = timeOffset;
                parseSchedule(settings);
                store(settings);
            } else if (options === 'currentTime') {
                return getCurrentTime();
            } else if (options === 'scheduleOffset') {
                let settings = $container.data('settings');
                settings.scheduleOffset = parseInt(param1);
                parseSchedule(settings);
                store(settings);
            } else if (options === 'identify') {
                for (let i = 0; i < 30; i++) {
                    setTimeout(function() {
                        if (i % 2 === 0) {
                            $container.addClass('warn');
                        } else {
                            $container.removeClass('warn');
                        }
                    }, i * 500);
                }
            }
        });
    }

    let local = JSON.parse(localStorage.getItem('timer'));
    local = local === null ? {} : local;
    if (!isValid(local)) {
        local = {};
    }

    let settings = $.extend({
        lookAheadHours: 6,
        timeOffset: 0,
        name: '',
        showClock: false,
        backstageMode: false,
        warnTime: 5,
        schedule: null,
        scheduleRaw: []
    }, local, options );

    timeOffset = parseInt(settings.timeOffset);

    return this.each(function() {
        const $container = $(this);
        const $timerMain = $('<span class="timer-main"></span>');
        const $timerSub = $('<span class="timer-sub"></span>');
        const $box = $('<div class="timer-box"></div>');
        $box.append($timerMain);
        $box.append($timerSub);
        $container.append($box);

        settings.scheduleOffset = parseInt(settings.scheduleOffset);
        if (settings.schedule !== null) {
            settings.scheduleRaw = settings.schedule;
        }
        parseSchedule(settings);
        $container.data('settings', settings);
        $('.lookaheadhours').text(settings.lookAheadHours);

        $container.on('click', '.schedule-restart-current, .schedule-skip-to-next', function(e) {
            let $target = $(e.target);
            if (!$target.is('button')) {
                $target = $target.closest('button');
            }
            $('.app-settings').appSettings('deltaScheduleOffset', $target.attr('data-schedule-offset'));
            return false;
        });

        $('body').on('heartbeat:seconds', function() {
            draw($container, $timerMain, $timerSub);
        });
        draw($container, $timerMain, $timerSub);
    });
};

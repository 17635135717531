const $ = require('jquery');
const dateHelper = require('./dateHelper.js');

let scheduleOffsetTimeout;

function isValid(settings) {
    return settings.warnTime >= 0 && settings.warnTime <= 15;
}

function serialize($container) {
    return {
        scheduleOffset: parseInt($container.find('input.scheduleoffset').val()),
        warnTime: $container.find('input.warntime').val()
    };
};

function update($container, settings) {
    if (isValid(settings)) {
        $container.find('input.scheduleoffset-range').val(seconds2Scale(parseInt(settings.scheduleOffset)));
        $container.find('.scheduleoffset-human').text(dateHelper.secToTime(parseInt(settings.scheduleOffset)));
        $container.find('input.scheduleoffset').val(settings.scheduleOffset);
        $container.find('input.warntime').val(settings.warnTime);
        store(settings);
        $container.trigger('appsettings:update', [settings]);
    }
};

function deltaScheduleOffset($container, delta) {
    let settings = serialize($container);
    settings.scheduleOffset = parseFloat(settings.scheduleOffset) + parseFloat(delta);
    update($container, settings);
    $container.trigger('appsettings:change', [settings]);
};

function store(client) {
    localStorage.setItem('app-settings', JSON.stringify(client));
};

function scale2Minutes(value) {
    const sign = value >= 0 ? 1 : -1;
    value = Math.abs(value);
    if (value >= 19) {value = (value - 19) * 60 + 180;}
    else if (value >= 17) {value = (value - 17) * 30 + 90;}
    else if (value >= 15) {value = (value - 15) * 15 + 45;}
    else if (value >= 11) {value = (value - 11) * 5 + 15;}
    value *= sign;
    return value;
}

function minutes2Scale(value) {
    const sign = value >= 0 ? 1 : -1;
    value = Math.abs(value);
    if (value >= 180) {value = (value - 180) / 60 + 19;}
    else if (value >= 90) {value = (value - 90) / 30 + 17;}
    else if (value >= 45) {value = (value - 45) / 15 + 15;}
    else if (value >= 15) {value = (value - 15) / 5 + 11;}
    value *= sign;
    return value;
}

function scale2Seconds(value) {
    const sign = value >= 0 ? 1 : -1;
    value = Math.abs(value);
    if (value >= 19) {value = (value - 19) * 60 + 180;}
    else if (value >= 17) {value = (value - 17) * 30 + 90;}
    else if (value >= 15) {value = (value - 15) * 15 + 45;}
    else if (value >= 11) {value = (value - 11) * 5 + 15;}
    value *= sign;
    value *= 60;
    return value;
}

function seconds2Scale(value) {
    const sign = value >= 0 ? 1 : -1;
    value = Math.abs(value);
    value /= 60;
    if (value >= 180) {value = (value - 180) / 60 + 19;}
    else if (value >= 90) {value = (value - 90) / 30 + 17;}
    else if (value >= 45) {value = (value - 45) / 15 + 15;}
    else if (value >= 15) {value = (value - 15) / 5 + 11;}
    value *= sign;
    return value;
}

$.fn.appSettings = function(options, param1) {
    if (typeof options === 'string') {
        if (options === 'serialize') {
            return serialize($(this));
        }
        return this.each(function() {
            let $container = $(this);
            if (options === 'update') {
                update($container, param1);
            } else if (options === 'deltaScheduleOffset') {
                deltaScheduleOffset($container, param1);
            }
        });
    }

    let local = JSON.parse(localStorage.getItem('app-settings'));
    local = local === null ? {} : local;
    if (!isValid(local)) {
        local = {};
    }

    let settings = $.extend({
        warnTime: 5,
        scheduleOffset: 0
    }, local, options );

    return this.each(function() {
        const $container = $(this);
        update($container, settings);

        $container.find('.mode-toggler').on('mode-toggler-change', function() {
            $container.find('.mode-toggler').each(function() {
                if ($(this).is(':checked')) {
                    $('.mode-' + $(this).val()).show();
                } else {
                    $('.mode-' + $(this).val()).hide();
                }
            });
        }).trigger('mode-toggler-change');
        $container.find('input.scheduleoffset-range').on('input', function() {
            let value = scale2Seconds(parseInt($(this).val()));
            $container.find('.scheduleoffset-human').text(dateHelper.secToTime(value));

            clearTimeout(scheduleOffsetTimeout);
            scheduleOffsetTimeout = setTimeout(function() {
                $container.find('input.scheduleoffset').val(value).trigger('change');
            }, 1000);
        }).trigger('input');

        $container.on('change', function(e) {
            const $target = $(e.target);
            if ($target.is('.mode-toggler')) {
                $target.trigger('mode-toggler-change');
            }
            const settings = serialize($container);
            if (isValid(settings)) {
                store(settings);
                $container.trigger('appsettings:change', [settings]);
            }
        });

        $container.on('click', '#schedule-import', function() {
            $container.trigger('schedule:import');
        });
        $container.on('click', '#schedule-export', function() {
            $container.trigger('schedule:export');
        });
    });
};

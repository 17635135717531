const $ = require('jquery');
const semver = require('semver');
const dateHelper = require('./dateHelper.js');

function addDate($container, values) {
    values = $.extend({
        date: null,
        description: ''
    }, values);
    if (!values.date) {
        let nextDate = new Date();
        values.date = dateHelper.formatDate(nextDate);
        $container.find('.schedule-date input.date').each(function() {
            nextDate = new Date(Date.parse($(this).val()+'T00:00'));
            nextDate = new Date(nextDate.setDate(nextDate.getDate() + 1));
            values.date = dateHelper.formatDate(nextDate);
        });
    }
    let $date = $($container.attr('data-date-template'));
    $date.find('input.date').val(values.date).inputmask({alias: 'datetime', inputFormat: 'yyyy-mm-dd'});
    $date.find('input.description').val(values.description);
    if ($container.find('.hint1, .hint2, .hint3').length) {
        $container.prepend($date);
    } else {
        $container.append($date);
    }
    return $date;
};

function addEntry($container, $date, values, $refEntry) {
    if ($refEntry) {
        values.duration = parseInt($refEntry.find('input.duration').val());
        if ($refEntry.closest('.schedule-date').find('input.date').val() !== $date.find('input.date').val()) {
            values.start = $refEntry.find('input.start').val();
            $refEntry = null;
        } else {
            let serialized = serialize($container, $refEntry);
            if (serialized.length === 1 && serialized[0].entries.length >= 1) {
                let changeover = 0;
                if (serialized[0].entries.length >= 2) {
                    changeover = (
                        (
                            serialized[0].entries[serialized[0].entries.length-1].start -
                            serialized[0].entries[serialized[0].entries.length-2].start
                        ) / 60000) -
                        serialized[0].entries[serialized[0].entries.length-2].duration;
                }
                values.start = dateHelper.formatTime(dateHelper.dateAdd(
                    serialized[0].entries[serialized[0].entries.length-1].start,
                    (values.duration + changeover) + 'm'
                ));
            }
        }
    }

    values = $.extend({
        start: '',
        duration: 60,
        description: ''
    }, values);

    var $entry = $($container.attr('data-entry-template'));
    $entry.find('input.start').val(values.start).inputmask({alias: 'datetime', inputFormat: 'HH:MM'});
    $entry.find('input.duration').val(values.duration).inputmask('9{1,3}');
    $entry.find('input.description').val(values.description);

    if ($refEntry) {
        $entry.insertAfter($refEntry);
    } else {
        $date.find('.schedule-entries').append($entry);
    }
    return $entry;
};

function calculateEnding($container) {
    serialize($container);
};

function duplicateDate($container, $fromDate) {
    const $date = addDate($container, {description: $fromDate.find('input.description').val()});
    $fromDate.find('.schedule-entry').each(function() {
        addEntry($container, $date, {}, $(this));
    });
};

function serialize($container, $stopEntry) {
    let result = [];
    let $dates;
    if ($stopEntry) {
        $dates = $stopEntry.closest('.schedule-date');
    } else {
        $dates = $container.find('.schedule-date');
    }
    $dates.each(function() {
        let row = {
            date: new Date(Date.parse($(this).find('input.date').val() + 'T00:00')),
            description: $(this).find('input.description').val(),
            entries: []
        };
        let lastEntry = row.date;
        let $entries;
        if ($stopEntry) {
            $entries = $stopEntry.siblings('.schedule-entry').not($stopEntry.next());
            $entries.push($stopEntry);
        } else {
            $entries = $(this).find('.schedule-entry');
        }
        $entries.each(function() {
            let entry = {
                start: new Date(Date.parse(dateHelper.formatDate(row.date) + 'T' + $(this).find('input.start').val())),
                duration: parseInt($(this).find('input.duration').val()),
                description: $(this).find('input.description').val()
            };
            for (let i = 1; entry.start < lastEntry; i++) {
                entry.start = dateHelper.dateAdd(entry.start, i + 'd');
            }
            $(this).find('input.end').val(dateHelper.formatTime(dateHelper.dateAdd(entry.start, entry.duration + 'm')));
            lastEntry = entry.start;
            row.entries.push(entry);
        });
        result.push(row);
    });
    return result;
};

function loadSchedule($container, schedule) {
    hideHints($container);
    $container.find('.schedule-date').remove();
    $.each(schedule, function(idx, dateSchedule) {
        let date = dateSchedule.date;
        if (typeof date === 'string') {
            date = new Date(date);
        }
        let $date = addDate($container, {
            date: dateHelper.formatDate(date),
            description: dateSchedule.description
        });
        $.each(dateSchedule.entries, function(idx, entry) {
            let start = entry.start;
            if (typeof start === 'string') {
                start = new Date(start);
            }
            entry.start = dateHelper.formatTime(start, false);
            addEntry($container, $date, entry);
        });
    });
    calculateEnding($container);
};

function showHint1($container) {
    hideHints($container);
    const $hint = $($container.attr('data-hint1-template'));
    $container.after($hint);
    $hint.on('click', '.create-date-hint', function() {
        addEntry($container, addDate($container));
        showHint2($container);
    });
};
function showHint2($container) {
    hideHints($container);
    const $hint = $($container.attr('data-hint2-template'));
    $container.after($hint);
};
function showHint3($container) {
    if ($container.nextAll('.hint2').length === 0) {
        return;
    }
    hideHints($container);
    const $hint = $($container.attr('data-hint3-template'));
    $container.after($hint);
    setTimeout(function() {
        $hint.remove();
    }, 15000);
};
function hideHints($container) {
    $container.nextAll('.hint1, .hint2, .hint3').remove();
};

function isValid($container) {
    return true;
};

$.fn.scheduleAdmin = function(options, param1) {
    if (typeof options === 'string') {
        return this.each(function() {
            let $container = $(this);
            if (options === 'export') {
                versionedSchedule = {
                    version: SHOWTIMER_VERSION,
                    schedule: serialize($container),
                };
                let $el = $('<a>');
                $el.attr('href', 'data:application/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(versionedSchedule, null, 4)));
                $el.attr('download', 'showtimer-schedule.json');
                $el.get(0).click();
            }
            if (options === 'import') {
                let $el = $('<input type="file">');
                $el.change(function(event) {
                    const uploadedFile = event.target.files[0];
                    if (uploadedFile.type !== 'application/json') {
                        alert('Not a valid schedule file');
                        return false;
                    }
                    const reader = new FileReader();
                    reader.onload = function(event) {
                        upload = JSON.parse(event.target.result);
                        if (!upload.version || !semver.satisfies(upload.version, '1.1.x')) {
                            alert('Schedule is from an unsupported Showtimer version');
                            return false;
                        }
                        $container.scheduleAdmin('schedule', upload.schedule);
                        $container.trigger('change');
                        $('body').trigger('goto', [$('#page-admin-schedule')]);
                    }
                    reader.readAsText(uploadedFile);
                });
                $el.trigger('click');
            }
            if (options === 'schedule') {
                loadSchedule($container, $.extend(true, {}, param1));
                if ($container.find('.schedule-date').length === 0) {
                    showHint1($container);
                }
            }
        });
    }

    let settings = $.extend({
    }, options );

    return this.each(function() {
        let $container = $(this);

        $container.on('click', '.add-date', function() {
            addEntry($container, addDate($container));
            hideHints($container);
        });
        $container.on('click', '.add-entry', function() {
            addEntry($container, $(this).closest('.schedule-date'), {}, $(this).closest('.schedule-entry'));
            $container.trigger('change');
            showHint3($container);
        });
        $container.on('click', '.duplicate-date', function() {
            duplicateDate($container, $(this).closest('.schedule-date'));
            $container.trigger('change');
            hideHints($container);
        });
        $container.on('click', '.remove-date', function() {
            $(this).closest('.schedule-date').remove();
            if ($container.find('.schedule-date').length === 0) {
                showHint1($container);
            }
            $container.trigger('change');
        });
        $container.on('click', '.remove-entry', function() {
            $(this).closest('.schedule-entry').remove();
            $container.trigger('change');
        });
        $container.on('change', function() {
            if (isValid($container)) {
                $container.trigger('schedule:change', [serialize($container)]);
            }
        });
    });
};
